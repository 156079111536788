<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="card">
      <div class="card-header">Bank Details</div>
      <div class="card-body">
        <v-form ref="form" lazy-validation v-model="valid">
          <div class="row">                                      
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
              <div class="form-group">
                <label class="control-label pb-2" for="phone">Bank Name</label>
                <v-text-field type="text" v-model="update.bank_name" placeholder="Bank of Scotland" :rules="[v => !!v || 'Enter Bank Name']" outlined></v-text-field>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0 pt-0">
              <div class="form-group">
                <label class="control-label pb-2" for="phone">Account Holder Name</label>
                <v-text-field type="text" v-model="update.account_holder_name" placeholder="Aida Bugg" :rules="[v => !!v || 'Enter Account Holder Name']" outlined></v-text-field>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
              <div class="form-group">
                <label class="control-label pb-2" for="phone">Account Number</label>
                <v-text-field type="text" v-model="update.account_number" placeholder="1234567891231212" :rules="[v => !!v || 'Enter Account Number']" outlined></v-text-field>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0 pt-0">
              <div class="form-group">
                <label class="control-label pb-2" for="phone">Swift Code</label>
                <v-text-field type="text" v-model="update.swift_code" placeholder="BOST98215233" :rules="[v => !!v || 'Enter Swift Code']" outlined></v-text-field>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 ">
              <a v-on:click="saveBankDetails" class="btn btn-success btn-block text-white w-100">Save Changes</a>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Updated Succussfully</div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'Tutor-BankDetails',
  props:['tutor'],
  data(){
    return{
      valid:false,
      update:{
        tutor_id:null,
        account_number:null,
        account_holder_name:null,
        swift_code:null,
        bank_name:null
      }
    }
  },
  beforeMount(){
    this.update.tutor_id = this.tutor.tutor_id;
    this.update.bank_name = (this.tutor.bank&&this.tutor.bank.bank_name)?this.tutor.bank.bank_name:null;
    this.update.account_holder_name = (this.tutor.bank&&this.tutor.bank.account_holder_name)?this.tutor.bank.account_holder_name:null;
    this.update.account_number = (this.tutor.bank&&this.tutor.bank.account_number)?this.tutor.bank.account_number:null;
    this.update.swift_code = (this.tutor.bank&&this.tutor.bank.swift_code)?this.tutor.bank.swift_code:null;
  },
  methods:{
    openModal: function (type = "") {
      this.open = (type == "")? this.open : type;
      if (this.open == "success") {
        this.$refs["success"].show();
      }
    },
    saveBankDetails(e){
      e.preventDefault();
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tutor_bank',this.update,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.data.message == 'Bank Created successfully'){
            this.openModal("success");
              setTimeout(() => {
                this.$refs["success"].hide();
            },2000)
          this.tutor.bank = {};
          this.tutor.bank.bank_name = res.data.data.bank_name;
          this.tutor.bank.account_holder_name = res.data.data.account_holder_name;
          this.tutor.bank.account_number = res.data.data.account_number;
          this.tutor.bank.swift_code = res.data.data.swift_code;
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px !important;
    color: #5f5e61;
    line-height: 2.3;
}
.v-input{
  font-size: 14px !important;
}
</style>